@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Lato", sans-serif;
    background-color: #eeeeee;
  }

  button,
  .button {
    @apply py-1 px-5 rounded-lg bg-[#ef5b0c] w-auto bg-opacity-90;
  }

  button:hover,
  .button:hover {
    @apply bg-opacity-100;
  }

  p,
  h3,
  h4,
  h5 {
    font-family: "Inter", sans-serif;
  }
}

.aboutBg {
  border-radius: 0px 180px;
}
/* 640px */
@media only screen and (max-width: 767px) {
  .aboutBg {
    border-radius: 0 0 0 120px;
  }
  .active {
    text-decoration: underline;
    text-decoration-color: #272343;
    /* text-decoration-thickness: 3px; */
    pointer-events: none;
  }
}

@media only screen and (min-width: 768px) {
  .aboutBg {
    border-radius: 0 260px;
  }
  .active {
    display: flex;
    align-items: center;
    /* background-color: #dcdcdc; */
    background-color: #ef5b0c;
    /* border: solid #dcdcdc 2px; */
    padding: 0 10px;
    height: 4.5rem;
    /* border-radius: 6px; */
    color: #172b4d !important;
    font-weight: bold;
    pointer-events: none;
  }
  .active {
    /* font-weight: bold !important; */
    /* scale: 0.8; */
  }
  /* .active:hover {
    scale: 0.98 !important;
    transition-duration: 700ms;
  } */
}
@media only screen and (min-width: 1280px) {
  .aboutBg {
    border-radius: 0 360px;
  }
}

@media only screen and (min-width: 1050px) {
  .image-gallery {
    width: 75%;
    height: auto;
    margin: auto;
  }
}
@media only screen and (min-width: 1250px) {
  .image-gallery {
    width: 55%;
    height: auto;
    margin: auto;
  }
}
